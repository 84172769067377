import * as i0 from '@angular/core';
import { Component, ChangeDetectionStrategy, Input, HostBinding, createComponent, InjectionToken, inject, ApplicationRef, Injectable, ViewContainerRef, ViewChild } from '@angular/core';
import * as i1 from '@ngx-translate/core';
import { TranslateModule } from '@ngx-translate/core';
import { LfIconComponent } from '@leafio/ui/icons';
import { style, animate, transition, trigger } from '@angular/animations';
import { Subject } from 'rxjs';
import { CommonModule } from '@angular/common';
function LfToastComponent_Conditional_8_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "p", 10);
    i0.ɵɵtext(1);
    i0.ɵɵpipe(2, "translate");
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate1(" ", i0.ɵɵpipeBind1(2, 1, ctx_r0.config.text), " ");
  }
}
function LfToastComponent_Conditional_9_Template(rf, ctx) {
  if (rf & 1) {
    const _r3 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "button", 11);
    i0.ɵɵlistener("click", function LfToastComponent_Conditional_9_Template_button_click_0_listener() {
      i0.ɵɵrestoreView(_r3);
      const ctx_r2 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r2.callAction());
    });
    i0.ɵɵtext(1);
    i0.ɵɵpipe(2, "translate");
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate1(" ", i0.ɵɵpipeBind1(2, 1, ctx_r1.config.action), " ");
  }
}
const _c0 = ["outlet"];
const animationStates = {
  slideOut: {
    transform: 'translateX(calc(100% + var(--lf-toast-horizontal-spacing)))'
  },
  collapse: {
    height: 0,
    padding: 0,
    margin: 0
  },
  slideIn: {
    transform: 'translateX(0%)'
  },
  expand: {
    height: '*',
    padding: '*',
    margin: '*'
  }
};
class LfToastComponent {
  get hostClasses() {
    return `lf-` + this.config.type;
  }
  callAction() {
    this.instance.callAction();
    this.instance.close('action');
  }
  close() {
    this.instance.close('user');
  }
  static {
    this.ɵfac = function LfToastComponent_Factory(t) {
      return new (t || LfToastComponent)();
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: LfToastComponent,
      selectors: [["lf-toast"]],
      hostVars: 3,
      hostBindings: function LfToastComponent_HostBindings(rf, ctx) {
        if (rf & 2) {
          i0.ɵɵsyntheticHostProperty("@toastAnimation", true);
          i0.ɵɵclassMap(ctx.hostClasses);
        }
      },
      inputs: {
        config: "config",
        instance: "instance"
      },
      standalone: true,
      features: [i0.ɵɵStandaloneFeature],
      decls: 12,
      vars: 6,
      consts: [[1, "indicator", "lf-flex-item-fixed"], [1, "content", "lf-flex", "lf-flex-item-fluid", "lf-align-center"], [1, "icon", "lf-flex", "lf-flex-item-fixed"], [1, "icon__content", 3, "name"], [1, "content__text", "lf-vertical-flex", "lf-flex-item-fluid"], [1, "content__header"], ["class", "content__description"], ["type", "button", "class", "content__action"], ["type", "button", 1, "close-btn", "lf-flex", "lf-flex-item-fixed", 3, "click"], ["name", "close", 1, "close-btn-icon"], [1, "content__description"], ["type", "button", 1, "content__action", 3, "click"]],
      template: function LfToastComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵelement(0, "div", 0);
          i0.ɵɵelementStart(1, "div", 1)(2, "div", 2);
          i0.ɵɵelement(3, "lf-icon", 3);
          i0.ɵɵelementEnd();
          i0.ɵɵelementStart(4, "div", 4)(5, "p", 5);
          i0.ɵɵtext(6);
          i0.ɵɵpipe(7, "translate");
          i0.ɵɵelementEnd();
          i0.ɵɵtemplate(8, LfToastComponent_Conditional_8_Template, 3, 3, "p", 6)(9, LfToastComponent_Conditional_9_Template, 3, 3, "button", 7);
          i0.ɵɵelementEnd();
          i0.ɵɵelementStart(10, "button", 8);
          i0.ɵɵlistener("click", function LfToastComponent_Template_button_click_10_listener() {
            return ctx.close();
          });
          i0.ɵɵelement(11, "lf-icon", 9);
          i0.ɵɵelementEnd()();
        }
        if (rf & 2) {
          i0.ɵɵadvance(3);
          i0.ɵɵproperty("name", ctx.config.icon);
          i0.ɵɵadvance(3);
          i0.ɵɵtextInterpolate1(" ", i0.ɵɵpipeBind1(7, 4, ctx.config.title), " ");
          i0.ɵɵadvance(2);
          i0.ɵɵconditional(8, ctx.config.text ? 8 : -1);
          i0.ɵɵadvance();
          i0.ɵɵconditional(9, ctx.config.action ? 9 : -1);
        }
      },
      dependencies: [TranslateModule, i1.TranslatePipe, LfIconComponent],
      styles: ["[_nghost-%COMP%]{pointer-events:all;display:flex;align-items:center;gap:14px;width:var(--lf-toast-width);padding:10px 20px 10px 12px;background-color:var(--lf-bg-100);margin-right:var(--lf-toast-horizontal-spacing);margin-top:var(--lf-toast-gap);border-radius:8px;box-shadow:var(--lf-elevation-300);transition:margin-top var(--lf-transition-duration)}[_nghost-%COMP%]:first-child{margin-top:var(--lf-toast-vertical-spacing)}.indicator[_ngcontent-%COMP%]{width:4px;align-self:stretch;background-color:var(--lf-current-accent);border-radius:2px}.content[_ngcontent-%COMP%]{gap:20px}.icon[_ngcontent-%COMP%]{width:40px;height:40px;background-color:var(--lf-current-accent);border:7px solid var(--lf-current-accent-100);border-radius:50%}.icon__content[_ngcontent-%COMP%]{margin:auto;font-size:16px;color:var(--lf-current-accent-text)}.content__text[_ngcontent-%COMP%]{padding:4px 0;gap:6px}.content__header[_ngcontent-%COMP%]{color:var(--lf-text-900);font:var(--lf-h6-font)}.content__description[_ngcontent-%COMP%]{color:var(--lf-text-500);font:var(--lf-text-l-font)}.close-btn[_ngcontent-%COMP%]{border:none;outline:none;padding:0;background-color:transparent;color:var(--lf-main-700);font-size:24px;cursor:pointer;transition:color var(--lf-transition-duration)}.close-btn[_ngcontent-%COMP%]:hover{color:var(--lf-main-900)}.content__action[_ngcontent-%COMP%]{display:block;border:none;outline:none;padding:0;background-color:transparent;color:var(--lf-current-accent);font:var(--lf-text-font);cursor:pointer;transition:color var(--lf-transition-duration);text-align:left}.content__action[_ngcontent-%COMP%]:hover{color:var(--lf-current-accent-400)}"],
      data: {
        animation: [trigger('toastAnimation', [transition(':enter', [style({
          ...animationStates.collapse,
          ...animationStates.slideOut
        }), animate('200ms ease-in-out', style(animationStates.expand)), animate('200ms ease-in-out', style(animationStates.slideIn))]), transition(':leave', [animate('200ms ease-in-out', style(animationStates.slideOut)), animate('200ms ease-in-out', style(animationStates.collapse))])])]
      },
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(LfToastComponent, [{
    type: Component,
    args: [{
      selector: 'lf-toast',
      standalone: true,
      imports: [TranslateModule, LfIconComponent],
      changeDetection: ChangeDetectionStrategy.OnPush,
      animations: [trigger('toastAnimation', [transition(':enter', [style({
        ...animationStates.collapse,
        ...animationStates.slideOut
      }), animate('200ms ease-in-out', style(animationStates.expand)), animate('200ms ease-in-out', style(animationStates.slideIn))]), transition(':leave', [animate('200ms ease-in-out', style(animationStates.slideOut)), animate('200ms ease-in-out', style(animationStates.collapse))])])],
      host: {
        '[@toastAnimation]': 'true'
      },
      template: "<div class=\"indicator lf-flex-item-fixed\"></div>\n<div class=\"content lf-flex lf-flex-item-fluid lf-align-center\">\n  <div class=\"icon lf-flex lf-flex-item-fixed\">\n    <lf-icon [name]=\"config.icon\" class=\"icon__content\"></lf-icon>\n  </div>\n  <div class=\"content__text lf-vertical-flex lf-flex-item-fluid\">\n    <p class=\"content__header\">\n      {{ config.title | translate }}\n    </p>\n    @if (config.text) {\n      <p class=\"content__description\">\n        {{ config.text | translate }}\n      </p>\n    }\n    @if (config.action) {\n      <button type=\"button\" class=\"content__action\" (click)=\"callAction()\">\n        {{ config.action | translate }}\n      </button>\n    }\n  </div>\n  <button class=\"close-btn lf-flex lf-flex-item-fixed\" type=\"button\" (click)=\"close()\">\n    <lf-icon name=\"close\" class=\"close-btn-icon\"></lf-icon>\n  </button>\n</div>\n",
      styles: [":host{pointer-events:all;display:flex;align-items:center;gap:14px;width:var(--lf-toast-width);padding:10px 20px 10px 12px;background-color:var(--lf-bg-100);margin-right:var(--lf-toast-horizontal-spacing);margin-top:var(--lf-toast-gap);border-radius:8px;box-shadow:var(--lf-elevation-300);transition:margin-top var(--lf-transition-duration)}:host:first-child{margin-top:var(--lf-toast-vertical-spacing)}.indicator{width:4px;align-self:stretch;background-color:var(--lf-current-accent);border-radius:2px}.content{gap:20px}.icon{width:40px;height:40px;background-color:var(--lf-current-accent);border:7px solid var(--lf-current-accent-100);border-radius:50%}.icon__content{margin:auto;font-size:16px;color:var(--lf-current-accent-text)}.content__text{padding:4px 0;gap:6px}.content__header{color:var(--lf-text-900);font:var(--lf-h6-font)}.content__description{color:var(--lf-text-500);font:var(--lf-text-l-font)}.close-btn{border:none;outline:none;padding:0;background-color:transparent;color:var(--lf-main-700);font-size:24px;cursor:pointer;transition:color var(--lf-transition-duration)}.close-btn:hover{color:var(--lf-main-900)}.content__action{display:block;border:none;outline:none;padding:0;background-color:transparent;color:var(--lf-current-accent);font:var(--lf-text-font);cursor:pointer;transition:color var(--lf-transition-duration);text-align:left}.content__action:hover{color:var(--lf-current-accent-400)}\n"]
    }]
  }], null, {
    config: [{
      type: Input
    }],
    instance: [{
      type: Input
    }],
    hostClasses: [{
      type: HostBinding,
      args: ['class']
    }]
  });
})();
class LfToast {
  constructor(config, envInjector) {
    this.closeSubject = new Subject();
    this.closed = false;
    this.onClose = this.closeSubject.asObservable();
    this.config = config;
    this.envInjector = envInjector;
    this.componentRef = this.createComponent();
    if (this.config.duration > 0) {
      this.startAutoClose();
    }
    if (this.config.action) {
      this.actionSubject = new Subject();
      this.onAction = this.actionSubject.asObservable();
    }
  }
  close(reason = 'method') {
    if (this.closed) {
      return;
    }
    this.closed = true;
    this.destroy(reason);
  }
  callAction() {
    this.actionSubject?.next();
  }
  startAutoClose() {
    setTimeout(() => this.close('timeout'), this.config.duration);
  }
  createComponent() {
    const component = createComponent(this.config.component, {
      environmentInjector: this.envInjector
    });
    component.setInput('config', this.config);
    component.setInput('instance', this);
    return component;
  }
  destroy(reason) {
    this.closeSubject.next(reason);
    this.closeSubject.complete();
    this.actionSubject?.complete();
    this.componentRef.destroy();
  }
}
const LF_DEFAULT_TOAST_CONFIG = new InjectionToken('LF_DEFAULT_TOAST_CONFIG', {
  factory: () => ({})
});
const LF_DEFAULT_TOAST_ICONS = new InjectionToken('LF_DEFAULT_TOAST_ICONS', {
  factory: () => ({})
});
const LF_DEFAULT_TOAST_TITLES = new InjectionToken('LF_DEFAULT_TOAST_TITLES', {
  factory: () => ({})
});
const LF_MAX_TOASTS_COUNT = new InjectionToken('LF_MAX_TOASTS_COUNT', {
  factory: () => 5
});
class LfToastService {
  constructor() {
    this.LF_DEFAULT_TOAST_ICONS = inject(LF_DEFAULT_TOAST_ICONS);
    this.defaultIcons = {
      brand: 'check_bold',
      success: 'check_bold',
      info: 'info_bold',
      warning: 'info_bold',
      attention: 'info_bold',
      danger: 'close_bold',
      ...this.LF_DEFAULT_TOAST_ICONS
    };
    this.LF_DEFAULT_TOAST_TITLES = inject(LF_DEFAULT_TOAST_TITLES);
    this.defaultTitles = {
      brand: 'Success',
      success: 'Success',
      info: 'Info',
      warning: 'Warning',
      attention: 'Attention',
      danger: 'Error',
      ...this.LF_DEFAULT_TOAST_TITLES
    };
    this.LF_DEFAULT_TOAST_CONFIG = inject(LF_DEFAULT_TOAST_CONFIG);
    this.defaultConfig = {
      text: '',
      type: 'success',
      icon: this.defaultIcons.success,
      duration: 5000,
      title: this.defaultTitles.success,
      action: '',
      component: LfToastComponent,
      ...this.LF_DEFAULT_TOAST_CONFIG
    };
    this.maxToasts = inject(LF_MAX_TOASTS_COUNT);
    this.appRef = inject(ApplicationRef);
    this.toastSubject = new Subject();
    this.toasts = [];
    this.onToast = this.toastSubject.asObservable();
  }
  success(text, config = {}) {
    return this.show({
      type: 'success',
      text: text,
      icon: this.defaultIcons.success,
      ...config
    });
  }
  brand(text, config = {}) {
    return this.show({
      type: 'brand',
      text: text,
      icon: this.defaultIcons.brand,
      ...config
    });
  }
  info(text, config = {}) {
    return this.show({
      type: 'info',
      text: text,
      icon: this.defaultIcons.info,
      ...config
    });
  }
  warning(text, config = {}) {
    return this.show({
      type: 'warning',
      text: text,
      icon: this.defaultIcons.warning,
      ...config
    });
  }
  attention(text, config = {}) {
    return this.show({
      type: 'attention',
      text: text,
      icon: this.defaultIcons.attention,
      ...config
    });
  }
  danger(text, config = {}) {
    return this.show({
      type: 'danger',
      text: text,
      icon: this.defaultIcons.danger,
      ...config
    });
  }
  show(config = {}) {
    if (!config.type) {
      config.type = this.defaultConfig.type;
    }
    if (!config.icon) {
      config.icon = this.defaultIcons[config.type];
    }
    if (!config.title) {
      config.title = this.defaultTitles[config.type];
    }
    const toast = new LfToast({
      ...this.defaultConfig,
      ...config
    }, this.appRef.injector);
    if (this.maxToasts && this.toasts.length >= this.maxToasts) {
      this.toasts[0].close('limit');
    }
    this.toasts.push(toast);
    toast.onClose.subscribe(() => {
      this.toasts = this.toasts.filter(t => toast !== t);
    });
    this.toastSubject.next(toast);
    return toast;
  }
  static {
    this.ɵfac = function LfToastService_Factory(t) {
      return new (t || LfToastService)();
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: LfToastService,
      factory: LfToastService.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(LfToastService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], null, null);
})();
class LfToastsOutlet {
  constructor() {
    this.toastService = inject(LfToastService);
  }
  insert(toast) {
    this.outlet.insert(toast.componentRef.hostView, 0);
    toast.componentRef.changeDetectorRef.detectChanges();
  }
  ngOnInit() {
    this.toastsSubscription = this.toastService.onToast.subscribe(toast => this.insert(toast));
  }
  ngOnDestroy() {
    this.toastsSubscription?.unsubscribe();
  }
  static {
    this.ɵfac = function LfToastsOutlet_Factory(t) {
      return new (t || LfToastsOutlet)();
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: LfToastsOutlet,
      selectors: [["lf-toasts-outlet"]],
      viewQuery: function LfToastsOutlet_Query(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵviewQuery(_c0, 5, ViewContainerRef);
        }
        if (rf & 2) {
          let _t;
          i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.outlet = _t.first);
        }
      },
      standalone: true,
      features: [i0.ɵɵStandaloneFeature],
      decls: 2,
      vars: 0,
      consts: [["outlet", ""]],
      template: function LfToastsOutlet_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵelementContainer(0, null, 0);
        }
      },
      dependencies: [CommonModule],
      styles: ["[_nghost-%COMP%]{pointer-events:none;display:block;position:fixed;top:0;right:0;z-index:var(--lf-z-index-toasts)}"],
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(LfToastsOutlet, [{
    type: Component,
    args: [{
      selector: 'lf-toasts-outlet',
      standalone: true,
      imports: [CommonModule],
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: "<ng-container #outlet></ng-container>\n",
      styles: [":host{pointer-events:none;display:block;position:fixed;top:0;right:0;z-index:var(--lf-z-index-toasts)}\n"]
    }]
  }], null, {
    outlet: [{
      type: ViewChild,
      args: ['outlet', {
        read: ViewContainerRef
      }]
    }]
  });
})();

/**
 * Generated bundle index. Do not edit.
 */

export { LF_DEFAULT_TOAST_CONFIG, LF_DEFAULT_TOAST_ICONS, LF_DEFAULT_TOAST_TITLES, LF_MAX_TOASTS_COUNT, LfToast, LfToastService, LfToastsOutlet };
