import * as i0 from '@angular/core';
import { Component, ChangeDetectionStrategy, Input, ApplicationRef, inject, createComponent, Injectable } from '@angular/core';
import { CommonModule } from '@angular/common';
import * as i1 from '@ngx-translate/core';
import { TranslateModule } from '@ngx-translate/core';
import { finalize } from 'rxjs';
function LfLoaderComponent_Conditional_8_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵnamespaceSVG();
    i0.ɵɵnamespaceHTML();
    i0.ɵɵelementStart(0, "p", 8);
    i0.ɵɵtext(1);
    i0.ɵɵpipe(2, "translate");
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate(i0.ɵɵpipeBind1(2, 1, ctx_r0.config.text));
  }
}
class LfLoaderComponent {
  constructor() {
    this.config = {};
  }
  static {
    this.ɵfac = function LfLoaderComponent_Factory(t) {
      return new (t || LfLoaderComponent)();
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: LfLoaderComponent,
      selectors: [["lf-loader"]],
      inputs: {
        config: "config"
      },
      standalone: true,
      features: [i0.ɵɵStandaloneFeature],
      decls: 9,
      vars: 1,
      consts: [[1, "content-wrapper", "lf-vertical-flex", "lf-align-center"], ["viewBox", "0 0 48 48", "fill", "none", "xmlns", "http://www.w3.org/2000/svg", 1, "spinner"], ["d", "M24 3C19.8466 3 15.7865 4.23163 12.333 6.53914C8.87959 8.84665 6.18797 12.1264 4.59853 15.9636C3.00909 19.8009 2.59322 24.0233 3.40351 28.0969C4.2138 32.1705 6.21386 35.9123 9.15076 38.8492C12.0877 41.7861 15.8295 43.7862 19.9031 44.5965C23.9767 45.4068 28.1991 44.9909 32.0364 43.4015C35.8736 41.812 39.1534 39.1204 41.4609 35.667C43.7684 32.2135 45 28.1534 45 24", "stroke", "url(#paint0_linear_127552_60599)", "stroke-width", "6", "stroke-linecap", "round"], ["d", "M24 3C20.7967 3 17.6359 3.73279 14.7595 5.14231C11.883 6.55182 9.36709 8.60069 7.40428 11.1321C5.44147 13.6636 4.08378 16.6105 3.4351 19.7474C2.78642 22.8843 2.86395 26.128 3.66176 29.2303C4.45956 32.3326 5.9565 35.2113 8.03801 37.6461C10.1195 40.0808 12.7304 42.0072 15.671 43.2776C18.6115 44.5481 21.8037 45.1291 25.0033 44.976C28.2029 44.823 31.3251 43.94 34.1309 42.3947", "stroke", "var(--lf-brand-500, #392EC9)", "stroke-width", "6", "stroke-linecap", "round", 1, "lf-loader-stroke"], ["id", "paint0_linear_127552_60599", "x1", "42", "y1", "39.6667", "x2", "46.6667", "y2", "22.6667", "gradientUnits", "userSpaceOnUse"], ["offset", "0", "stop-color", "var(--lf-brand-500, #392EC9)"], ["offset", "1", "stop-color", "var(--lf-brand-500, #392EC9)", "stop-opacity", "0"], ["class", "text lf-text-right"], [1, "text", "lf-text-right"]],
      template: function LfLoaderComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵelementStart(0, "div", 0);
          i0.ɵɵnamespaceSVG();
          i0.ɵɵelementStart(1, "svg", 1);
          i0.ɵɵelement(2, "path", 2)(3, "path", 3);
          i0.ɵɵelementStart(4, "defs")(5, "linearGradient", 4);
          i0.ɵɵelement(6, "stop", 5)(7, "stop", 6);
          i0.ɵɵelementEnd()()();
          i0.ɵɵtemplate(8, LfLoaderComponent_Conditional_8_Template, 3, 3, "p", 7);
          i0.ɵɵelementEnd();
        }
        if (rf & 2) {
          i0.ɵɵadvance(8);
          i0.ɵɵconditional(8, ctx.config.text ? 8 : -1);
        }
      },
      dependencies: [CommonModule, TranslateModule, i1.TranslatePipe],
      styles: ["[_nghost-%COMP%]{display:flex;position:absolute;inset:0;background-color:var(--lf-bg-100);z-index:var(--lf-z-index-loader)}.spinner[_ngcontent-%COMP%]{display:block;width:var(--lf-loader-spinner-size);height:var(--lf-loader-spinner-size);animation-name:_ngcontent-%COMP%_spinner-rotate;animation-duration:1s;animation-iteration-count:infinite;animation-timing-function:linear}.content-wrapper[_ngcontent-%COMP%]{margin:auto;gap:var(--lf-loader-gap)}.text[_ngcontent-%COMP%]{font:var(--lf-loader-text-font);color:var(--lf-main-700)}@keyframes _ngcontent-%COMP%_spinner-rotate{0%{transform:rotate(0)}to{transform:rotate(360deg)}}"],
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(LfLoaderComponent, [{
    type: Component,
    args: [{
      selector: 'lf-loader',
      standalone: true,
      imports: [CommonModule, TranslateModule],
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: "<div class=\"content-wrapper lf-vertical-flex lf-align-center\">\n  <svg class=\"spinner\"\n       viewBox=\"0 0 48 48\"\n       fill=\"none\"\n       xmlns=\"http://www.w3.org/2000/svg\">\n    <path d=\"M24 3C19.8466 3 15.7865 4.23163 12.333 6.53914C8.87959 8.84665 6.18797 12.1264 4.59853 15.9636C3.00909 19.8009 2.59322 24.0233 3.40351 28.0969C4.2138 32.1705 6.21386 35.9123 9.15076 38.8492C12.0877 41.7861 15.8295 43.7862 19.9031 44.5965C23.9767 45.4068 28.1991 44.9909 32.0364 43.4015C35.8736 41.812 39.1534 39.1204 41.4609 35.667C43.7684 32.2135 45 28.1534 45 24\"\n          stroke=\"url(#paint0_linear_127552_60599)\"\n          stroke-width=\"6\"\n          stroke-linecap=\"round\" />\n    <path d=\"M24 3C20.7967 3 17.6359 3.73279 14.7595 5.14231C11.883 6.55182 9.36709 8.60069 7.40428 11.1321C5.44147 13.6636 4.08378 16.6105 3.4351 19.7474C2.78642 22.8843 2.86395 26.128 3.66176 29.2303C4.45956 32.3326 5.9565 35.2113 8.03801 37.6461C10.1195 40.0808 12.7304 42.0072 15.671 43.2776C18.6115 44.5481 21.8037 45.1291 25.0033 44.976C28.2029 44.823 31.3251 43.94 34.1309 42.3947\"\n          class=\"lf-loader-stroke\"\n          stroke=\"var(--lf-brand-500, #392EC9)\"\n          stroke-width=\"6\"\n          stroke-linecap=\"round\" />\n    <defs>\n      <linearGradient id=\"paint0_linear_127552_60599\"\n                      x1=\"42\"\n                      y1=\"39.6667\"\n                      x2=\"46.6667\"\n                      y2=\"22.6667\"\n                      gradientUnits=\"userSpaceOnUse\">\n        <stop offset=\"0\"\n              stop-color=\"var(--lf-brand-500, #392EC9)\" />\n        <stop offset=\"1\"\n              stop-color=\"var(--lf-brand-500, #392EC9)\"\n              stop-opacity=\"0\" />\n      </linearGradient>\n    </defs>\n  </svg>\n  @if (config.text) {\n    <p class=\"text lf-text-right\">{{ config.text! | translate }}</p>\n  }\n</div>\n",
      styles: [":host{display:flex;position:absolute;inset:0;background-color:var(--lf-bg-100);z-index:var(--lf-z-index-loader)}.spinner{display:block;width:var(--lf-loader-spinner-size);height:var(--lf-loader-spinner-size);animation-name:spinner-rotate;animation-duration:1s;animation-iteration-count:infinite;animation-timing-function:linear}.content-wrapper{margin:auto;gap:var(--lf-loader-gap)}.text{font:var(--lf-loader-text-font);color:var(--lf-main-700)}@keyframes spinner-rotate{0%{transform:rotate(0)}to{transform:rotate(360deg)}}\n"]
    }]
  }], null, {
    config: [{
      type: Input
    }]
  });
})();
let uniqueId = 0;
class LfLoaderService {
  constructor() {
    this.appRef = inject(ApplicationRef);
  }
  static {
    this.defaultConfig = {
      component: LfLoaderComponent,
      hidingDelay: 500,
      text: '',
      hostElementClass: 'lf-element-with-loader'
    };
  }
  init(defaultConfig = {}) {
    LfLoaderService.appRef = this.appRef;
    LfLoaderService.defaultConfig = {
      ...LfLoaderService.defaultConfig,
      ...defaultConfig
    };
  }
  static getHostElementByConfig(config) {
    if (config.target instanceof HTMLElement) {
      return config.target;
    }
    return config.target ? document.querySelector(config.target) || document.body : document.body;
  }
  static updateCounterForHost(host, delta) {
    host.dataset['lfLoaderCount'] ||= '0';
    let loadersCount = Number(host.dataset['lfLoaderCount']);
    loadersCount += delta;
    host.dataset['lfLoaderCount'] = loadersCount.toString();
    return loadersCount;
  }
  static show(config = {}) {
    if (!LfLoaderService.appRef) {
      return null;
    }
    config = {
      ...LfLoaderService.defaultConfig,
      ...config
    };
    let hostElement = this.getHostElementByConfig(config);
    const componentRef = createComponent(config.component || LfLoaderService.component, {
      environmentInjector: LfLoaderService.appRef.injector
    });
    LfLoaderService.appRef.attachView(componentRef.hostView);
    componentRef.setInput('config', config);
    if (config.hostElementClass) {
      hostElement.classList.add(config.hostElementClass);
    }
    this.updateCounterForHost(hostElement, 1);
    const nativeElement = componentRef.location.nativeElement;
    hostElement.append(nativeElement);
    return {
      id: uniqueId++,
      config,
      componentRef,
      hostElement,
      nativeElement
    };
  }
  show(config = {}) {
    return LfLoaderService.show(config);
  }
  static hide(loader) {
    if (!loader) {
      return;
    }
    setTimeout(() => {
      loader.nativeElement.remove();
      if (loader.config.hostElementClass && this.updateCounterForHost(loader.hostElement, -1) === 0) {
        loader.hostElement.classList.remove(loader.config.hostElementClass);
      }
      loader.componentRef.destroy();
    }, loader.config.hidingDelay);
  }
  hide(loader) {
    return LfLoaderService.hide(loader);
  }
  static {
    this.ɵfac = function LfLoaderService_Factory(t) {
      return new (t || LfLoaderService)();
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: LfLoaderService,
      factory: LfLoaderService.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(LfLoaderService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], null, null);
})();
function LfLoaderByPromise(config = {}) {
  return function (target, propertyKey, descriptor) {
    const originalMethod = descriptor.value;
    descriptor.value = function (...args) {
      if (config.elementRef && 'elementRef' in this) {
        config.target = this.elementRef.nativeElement;
      }
      let loader = LfLoaderService.show(config);
      const result = originalMethod.apply(this, args);
      if (!loader) {
        return result;
      }
      // Promise.resolve is to convert any value to promise
      return Promise.resolve(result).then(res => {
        LfLoaderService.hide(loader);
        return res;
      }).catch(err => {
        LfLoaderService.hide(loader);
        return Promise.reject(err);
      });
    };
    return descriptor;
  };
}
function LfLoaderByObservable(config = {}) {
  return function (target, propertyKey, descriptor) {
    const originalMethod = descriptor.value;
    descriptor.value = function (...args) {
      if (config.elementRef && 'elementRef' in this) {
        config.target = this.elementRef.nativeElement;
      }
      let loader = LfLoaderService.show(config);
      const result = originalMethod.apply(this, args);
      if (!loader) {
        return result;
      }
      return result.pipe(finalize(() => LfLoaderService.hide(loader)));
    };
    return descriptor;
  };
}

/**
 * Generated bundle index. Do not edit.
 */

export { LfLoaderByObservable, LfLoaderByPromise, LfLoaderComponent, LfLoaderService };
