import * as i0 from '@angular/core';
import { EventEmitter, Component, ChangeDetectionStrategy, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LfSvgIconComponent } from '@leafio/ui/svg-icons';
import { LfIconComponent } from '@leafio/ui/icons';
import * as i1 from '@ngx-translate/core';
import { TranslateModule } from '@ngx-translate/core';
import { LfButton } from '@leafio/ui/buttons';
function LfPlaceholder_Conditional_1_Conditional_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "lf-svg-icon", 6);
  }
  if (rf & 2) {
    const ctx_r3 = i0.ɵɵnextContext(2);
    i0.ɵɵproperty("src", ctx_r3.svgIconSrc)("name", ctx_r3.svgIconName);
  }
}
function LfPlaceholder_Conditional_1_Conditional_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "lf-icon", 7);
  }
  if (rf & 2) {
    const ctx_r4 = i0.ɵɵnextContext(2);
    i0.ɵɵproperty("name", ctx_r4.iconName);
  }
}
function LfPlaceholder_Conditional_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 4);
    i0.ɵɵtemplate(1, LfPlaceholder_Conditional_1_Conditional_1_Template, 1, 2, "lf-svg-icon", 5)(2, LfPlaceholder_Conditional_1_Conditional_2_Template, 1, 1);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵadvance();
    i0.ɵɵconditional(1, ctx_r0.svgIconSrc || ctx_r0.svgIconName ? 1 : 2);
  }
}
function LfPlaceholder_Conditional_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "p", 8);
    i0.ɵɵtext(1);
    i0.ɵɵpipe(2, "translate");
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate(i0.ɵɵpipeBind1(2, 1, ctx_r1.text));
  }
}
function LfPlaceholder_Conditional_3_Template(rf, ctx) {
  if (rf & 1) {
    const _r6 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "button", 9);
    i0.ɵɵlistener("click", function LfPlaceholder_Conditional_3_Template_button_click_0_listener() {
      i0.ɵɵrestoreView(_r6);
      const ctx_r5 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r5.onAction.emit());
    });
    i0.ɵɵtext(1);
    i0.ɵɵpipe(2, "translate");
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r2 = i0.ɵɵnextContext();
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate(i0.ɵɵpipeBind1(2, 1, ctx_r2.action));
  }
}
const _c0 = ["*"];
class LfPlaceholder {
  constructor() {
    this.onAction = new EventEmitter();
  }
  get isIconSet() {
    return !!(this.iconName || this.svgIconSrc || this.svgIconName);
  }
  static {
    this.ɵfac = function LfPlaceholder_Factory(t) {
      return new (t || LfPlaceholder)();
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: LfPlaceholder,
      selectors: [["lf-placeholder"]],
      inputs: {
        svgIconSrc: "svgIconSrc",
        svgIconName: "svgIconName",
        iconName: "iconName",
        text: "text",
        action: "action"
      },
      outputs: {
        onAction: "onAction"
      },
      standalone: true,
      features: [i0.ɵɵStandaloneFeature],
      ngContentSelectors: _c0,
      decls: 5,
      vars: 3,
      consts: [[1, "wrapper", "lf-vertical-flex", "lf-align-center"], ["class", "icon-wrapper"], ["class", "text"], ["type", "button", "lfBtn", "", "accent", "neutral", "mode", "primary"], [1, "icon-wrapper"], ["class", "svg-icon", "height", "100%", "width", "100%", 3, "src", "name"], ["height", "100%", "width", "100%", 1, "svg-icon", 3, "src", "name"], [1, "icon", 3, "name"], [1, "text"], ["type", "button", "lfBtn", "", "accent", "neutral", "mode", "primary", 3, "click"]],
      template: function LfPlaceholder_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵelementStart(0, "div", 0);
          i0.ɵɵtemplate(1, LfPlaceholder_Conditional_1_Template, 3, 1, "div", 1)(2, LfPlaceholder_Conditional_2_Template, 3, 3, "p", 2)(3, LfPlaceholder_Conditional_3_Template, 3, 3, "button", 3);
          i0.ɵɵprojection(4);
          i0.ɵɵelementEnd();
        }
        if (rf & 2) {
          i0.ɵɵadvance();
          i0.ɵɵconditional(1, ctx.isIconSet ? 1 : -1);
          i0.ɵɵadvance();
          i0.ɵɵconditional(2, ctx.text ? 2 : -1);
          i0.ɵɵadvance();
          i0.ɵɵconditional(3, ctx.action ? 3 : -1);
        }
      },
      dependencies: [CommonModule, LfSvgIconComponent, LfIconComponent, TranslateModule, i1.TranslatePipe, LfButton],
      styles: ["[_nghost-%COMP%]{display:flex;height:100%;width:100%;padding:var(--lf-placeholder-gap)}.wrapper[_ngcontent-%COMP%]{margin:auto;gap:var(--lf-placeholder-gap)}.icon-wrapper[_ngcontent-%COMP%]{text-align:center}.svg-icon[_ngcontent-%COMP%]{height:var(--lf-placeholder-icon-size);width:auto}.icon[_ngcontent-%COMP%]{color:var(--lf-main-700);font-size:var(--lf-placeholder-icon-size)}.text[_ngcontent-%COMP%]{text-align:center;color:var(--lf-main-700);font:var(--lf-placeholder-text-font)}"],
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(LfPlaceholder, [{
    type: Component,
    args: [{
      selector: 'lf-placeholder',
      standalone: true,
      imports: [CommonModule, LfSvgIconComponent, LfIconComponent, TranslateModule, LfButton],
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: "<div class=\"wrapper lf-vertical-flex lf-align-center\">\n  @if (isIconSet) {\n    <div class=\"icon-wrapper\">\n      @if (svgIconSrc || svgIconName) {\n        <lf-svg-icon class=\"svg-icon\" [src]=\"svgIconSrc\" [name]=\"svgIconName\" height=\"100%\" width=\"100%\"></lf-svg-icon>\n      } @else {\n        <lf-icon class=\"icon\" [name]=\"iconName\"></lf-icon>\n      }\n    </div>\n  }\n  @if (text) {\n    <p class=\"text\">{{ text! | translate }}</p>\n  }\n  @if (action) {\n    <button type=\"button\" lfBtn (click)=\"onAction.emit()\" accent=\"neutral\" mode=\"primary\">{{ action! | translate }}</button>\n  }\n  <ng-content />\n</div>\n",
      styles: [":host{display:flex;height:100%;width:100%;padding:var(--lf-placeholder-gap)}.wrapper{margin:auto;gap:var(--lf-placeholder-gap)}.icon-wrapper{text-align:center}.svg-icon{height:var(--lf-placeholder-icon-size);width:auto}.icon{color:var(--lf-main-700);font-size:var(--lf-placeholder-icon-size)}.text{text-align:center;color:var(--lf-main-700);font:var(--lf-placeholder-text-font)}\n"]
    }]
  }], null, {
    svgIconSrc: [{
      type: Input
    }],
    svgIconName: [{
      type: Input
    }],
    iconName: [{
      type: Input
    }],
    text: [{
      type: Input
    }],
    action: [{
      type: Input
    }],
    onAction: [{
      type: Output
    }]
  });
})();

/**
 * Generated bundle index. Do not edit.
 */

export { LfPlaceholder };
